// extracted by mini-css-extract-plugin
export var appLinks = "footer-module--appLinks--ff161";
export var container = "footer-module--container--76df5";
export var footerLink = "footer-module--footerLink--74efc";
export var linkDiv = "footer-module--linkDiv--c7f44";
export var linkGroupTitle = "footer-module--linkGroupTitle--342b2";
export var linkSubGroup = "footer-module--linkSubGroup--1a707";
export var linkText = "footer-module--linkText--cd1b2";
export var linkTextEmail = "footer-module--linkTextEmail--bbdff";
export var linksContainer = "footer-module--linksContainer--f6104";
export var linksGroup = "footer-module--linksGroup--7b96c";
export var title = "footer-module--title--ef13d";
export var titleContainer = "footer-module--titleContainer--cf89d";