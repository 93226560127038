/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, title, children }) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  // const metaDescription = description || site.siteMetadata.description
  // const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <meta charset="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        content="Wagetap empowers how you pay and get paid. Access your wages when you need them the most.."
      />
      <title>Getting Started – Wagetap!</title>
      {/* generic favicons */}
      <link
        rel="icon"
        href="/assets/img/favicon/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="/assets/img/favicon/apple-touch-icon-57x57.png"
        sizes="57x57"
      />
      <link
        rel="icon"
        href="/assets/img/favicon/apple-touch-icon-76x76.png"
        sizes="76x76"
      />
      <link
        rel="icon"
        href="/assets/img/favicon/favicon-96x96.png"
        sizes="96x96"
      />
      <link
        rel="icon"
        href="/assets/img/favicon/favicon-128.png"
        sizes="128x128"
      />

      {/* iOS favicons */}
      <link
        rel="apple-touch-icon"
        href="/assets/img/favicon/apple-touch-icon-120x120.png"
        sizes="120x120"
      />
      <link
        rel="apple-touch-icon"
        href="/assets/img/favicon/apple-touch-icon-152x152.png"
        sizes="152x152"
      />

      {/* windows favicons */}
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content="/assets/img/favicon/mstile-144x144.png"
      />
      {children}
    </>
  )
}

export default Seo
