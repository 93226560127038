import React from 'react'
import * as styles from './button.module.css'
import arrow from '../../../static/assets/img/arrow-right.svg'
import arrowWhite from '../../../static/assets/img/arrow-right-white.svg'
import arrowBlue from '../../../static/assets/img/arrow-top-right-blue.svg'

export const GreenButton = ({ leftArrow, className, children, ...props }) => (
  <button {...props} className={`${styles.button} ${className}`}>
    <div className="flex flex-row gap-2 items-center">
      <h2>{children}</h2>
      {leftArrow && !props.disabled && (
        <img src={arrow} alt="arrow" className="h-5 fill-white" />
      )}
    </div>
  </button>
)

export const BlueButton = ({ className, children, ...props }) => (
  <button
    {...props}
    className={`${styles.button} ${className} bg-blue text-white font-roobert tracking-wider px-6 py-3 hover:bg-darkBlue rounded-2xl focus:outline-none`}
  >
    <div className="flex flex-row justify-between gap-2 items-center">
      <h2>{children}</h2>
      <img src={arrowWhite} className="h-5 fill-white" alt="arrowWhite" />
    </div>
  </button>
)

export const LinkButton = ({ className, children, ...props }) => (
  <button
    {...props}
    className={`${styles.button} ${className} font-semibold tracking-wider text-lg text-blue font-roobert focus:outline-none`}
  >
    <div className="flex flex-row gap-2 items-center hover:opacity-75">
      <h2 className="pb-1">{children}</h2>
      <img src={arrowBlue} className="h-3 fill-blue" alt="arrowBlue" />
    </div>
  </button>
)
