import React, { useEffect, useRef, useState } from 'react'
import logo from './logo-white.svg'
import Container from '../container'
import * as styles from './navigation.module.css'
import AppLinks, { SignUpNavButton } from '../../app_links/app_links'

const isBrowser = typeof window !== 'undefined'

const NavLogo = () => (
  <a href="/" className="flex flex-row">
    <img alt="Wagetap logo" src={logo} />
  </a>
)

export default ({ data, assets, hasCustomerConsent }) => {
  const currActivePath = useRef()
  const [activePath, setActivePath] = useState(currActivePath.current)

  useEffect(() => {
    if (isBrowser) {
      currActivePath.current = window.location.pathname.replaceAll('/', '')
      setActivePath(currActivePath.current)
    }
  }, [])

  return (
    <div>
      {data && (
        <Container className={`${styles.navContainer}`}>
          <div className={styles.navLogo}>
            <NavLogo />
          </div>
          <div className={styles.navMenu}>
            {data.navPaths && (
              <Nav
                links={data.navPaths}
                activePath={activePath}
                className="flex flex-row items-center"
                hasCustomerConsent={hasCustomerConsent}
              />
            )}
          </div>
          {/* Mobile navigation */}
          <label htmlFor="mobile_menu_check" className={styles.mobileMenuCheck}>
            {data.navPaths && (
              <>
                <input
                  type="checkbox"
                  id="mobile_menu_check"
                  className={styles.checkbox}
                />
                <hr className={styles.menuLine1} />
                <hr className={styles.menuLine2} />
                <MobileNavScreen
                  links={data.navPaths}
                  activePath={activePath}
                  assets={assets}
                  hasCustomerConsent={hasCustomerConsent}
                />
              </>
            )}
          </label>
        </Container>
      )}
    </div>
  )
}

export const NavWithLogoOnly = () => (
  <Container>
    <NavLogo />
  </Container>
)

async function onNavigate(linkLabel, hasCustomerConsent) {
  if (isBrowser && hasCustomerConsent) {
    // Convert spaces to underscore, change to lowercase
    const eventLabel = linkLabel.replaceAll(' ', '_').toLowerCase()
    // e.g. website_nav_clicked > about_us
    await window.analytics.track('website_nav_clicked', {
      link: eventLabel,
    })
  }
  // Return true to navigate to href of anchor
  return true
}

const Nav = (props) => {
  return (
    <div>
      {props ? (
        <>
          <nav>
            <ul className={props.className}>
              {props.links
                .filter((link) => link.label || link.name)
                .map((l, idx) => {
                  const linkKey = `navigation-link-${idx}`

                  if (!l.internal || l.internal.type === 'ContentfulPath') {
                    return (
                      <li className={styles.navItem} key={linkKey}>
                        <a
                          onClick={async () => await onNavigate(l.label, props.hasCustomerConsent)}
                          href={l.url}
                          className={
                            props.activePath === l.url.replaceAll('/', '')
                              ? styles.navItemActive
                              : styles.navItemUnactive
                          }
                          key={`${l.label}-${idx.toString}`}
                        >
                          {l.label}
                        </a>
                      </li>
                    )
                  }

                  return (
                    <div className={`group ${styles.navItem}`} key={linkKey}>
                      <a
                        className={`${styles.navLinkDropdown} ${
                          l.linkEntries.some(
                            (link) =>
                              link.url.replaceAll('/', '') === props.activePath
                          )
                            ? 'text-white'
                            : 'hover:text-white'
                        }`}
                        key={`${l.name}-${idx.toString}`}
                      >
                        <p>{l.name}</p>

                        <svg
                          className="transform duration-150 group-hover:rotate-180"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.491"
                          height="9.429"
                          viewBox="0 0 16.491 9.429"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-down"
                            data-name="Icon ionic-ios-arrow-down"
                            d="M14.436,17.833l6.236-6.24a1.174,1.174,0,0,1,1.664,0,1.188,1.188,0,0,1,0,1.669l-7.065,7.07a1.176,1.176,0,0,1-1.625.034l-7.114-7.1A1.179,1.179,0,1,1,8.2,11.6Z"
                            transform="translate(-6.188 -11.246)"
                            fill="currentColor"
                          />
                        </svg>
                      </a>

                      <li className="pt-4 origin-top transition duration-150 transform absolute z-10 top-5 left-6 scale-y-0 group-hover:scale-y-100">
                        <div className="bg-[#232733] p-4 rounded-xl whitespace-nowrap flex flex-col space-y-4">
                          {l.linkEntries.map((link) => {
                            return (
                              <a
                                key={link.url}
                                onClick={async () =>
                                  await onNavigate(link.label)
                                }
                                href={link.url}
                                className={
                                  link.url.replaceAll('/', '') ===
                                  props.activePath
                                    ? 'text-white'
                                    : 'hover:text-white'
                                }
                              >
                                {link.label}
                              </a>
                            )
                          })}
                        </div>
                      </li>
                    </div>
                  )
                })}
              <li className={styles.navItem}>
                <SignUpNavButton className={styles.signUpButton} />
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

const MobileNavScreen = (props) => {
  return (
    <nav className={styles.mobileNavList}>
      <ul className={styles.mobileMenuItems}>
        {props.links
          .filter((link) => link.label || link.name)
          .map((l, idx) => {
            if (!l.internal || l.internal.type === 'ContentfulPath') {
              return (
                <li
                  className={styles.mobileNavItem}
                  key={`mobile-navigation-link-${idx}`}
                >
                  <a
                    onClick={async () => await onNavigate(l.label, props.hasCustomerConsent)}
                    href={l.url}
                    key={`mobile-${l.label}-${idx.toString}`}
                    className={
                      props.activePath === l.url.replaceAll('/', '')
                        ? styles.mobileNavLink
                        : undefined
                    }
                  >
                    {l.label}
                  </a>
                </li>
              )
            }

            return (
              <li key="mobile-nav" className={styles.mobileNavItem}>
                <p
                  className={`mb-6 ${
                    l.linkEntries.some(
                      (link) =>
                        link.url.replaceAll('/', '') === props.activePath
                    )
                      ? 'text-[#2673f7]'
                      : undefined
                  }`}
                >
                  {l.name}
                </p>

                <div className="flex flex-col space-y-6 ml-4">
                  {l.linkEntries.map((link) => {
                    return (
                      <a
                        key={link.url}
                        onClick={async () => await onNavigate(link.label)}
                        href={link.url}
                        className={
                          link.url.replaceAll('/', '') === props.activePath
                            ? 'text-[#2673f7]'
                            : undefined
                        }
                      >
                        {link.label}
                      </a>
                    )
                  })}
                </div>
              </li>
            )
          })}
      </ul>
      <div className={styles.appLinks}>
        <AppLinks assets={props.assets} browserLink={true} isBlack={false} />
      </div>
    </nav>
  )
}
