import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Typography from '../typography'
import Input from '../input'
import { GreenButton } from '../button'
import * as styles from './hero.module.css'
import Container from '../container'
import { useInterval } from '../../../utilities.js/utils'
// set position absolute
// force it to slide on specific path
const { Paragraph } = Typography
const isBrowser = typeof window !== 'undefined'

const POLLDELAY = 20000 // 20 s

export default ({ data, assets }) => {
  const [messageVisible, setMessageVisible] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('') // should be of type string

  const [disable, setDisable] = useState(true)
  const [delay, setDelay] = useState(null)

  useInterval(() => {
    setDelay(null)
    setDisable(!disable)
  }, delay)

  const validateInput = (value) => {
    const regexStr = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
    setDisable(!regexStr.test(value))
  }

  const sendEvent = (event) => {
    event.preventDefault()

    // const valSplit = inputValue.split(' ').join('')
    // const fullNumber = '+614' + valSplit
    // window.analytics.track('website_signup_sms_referal', {
    //   recipient: fullNumber,
    // })
    // setSuccessMessage(true)
    // setMessageVisible(true)

    // // disable btn until after such time
    // setDelay(POLLDELAY)
    // setDisable(true)
  }

  return (
    <div>
      {data && (
        <Container className={styles.heroContainer}>
          <div className={styles.heroTextContainer}>
            <div className={styles.tagContainer}>
              <h1 className={styles.tag}>{data.topBubbleText}</h1>
            </div>
            <h1 className={styles.title}>{data.title}</h1>
            <Paragraph className={styles.subtitle}>{data.subtitle}</Paragraph>
            {/* Phone number form, temporarily hidden because this feature is not working at the moment. */}

            {!data.hideInput && (
              <>
                <div className={styles.formTitle}>
                  <span className={styles.formTitleText}>{data.formTitle}</span>
                </div>
                <form
                  method="post"
                  name="mc-embedded-subscribe-form"
                  onSubmit={sendEvent}
                >
                  <div className={styles.formContainer}>
                    <div className={styles.mobileInputContainer}>
                      <Input
                        key="email"
                        // Do not change id, used for focus
                        id="email-input"
                        value={inputValue}
                        onChange={(e) => {
                          setMessageVisible(false)
                          validateInput(e.target.value)
                          setInputValue(e.target.value)
                        }}
                        type="email"
                        name="EMAIL"
                        placeholder={data.fieldPlaceholder}
                        className={styles.mobileInput}
                        required
                        size={1}
                      />
                      <GreenButton
                        name="subscribe"
                        type="submit"
                        disabled={disable}
                        // leftArrow={disable ? successMessage && messageVisible ? false : true : true}
                        className={styles.sendBtn}
                      >
                        {disable
                          ? successMessage && messageVisible
                            ? '👍'
                            : data.fieldButton
                          : data.fieldButton}
                      </GreenButton>
                    </div>
                  </div>
                </form>
              </>
            )}

            <Paragraph
              className={
                `${styles.phoneMessage} ` +
                (successMessage
                  ? `${styles.phoneSuccessMessage}`
                  : `${styles.phoneErrorMessage}`)
              }
            >
              &nbsp;
              {messageVisible && successMessage && data.successMessage}
              {messageVisible && !successMessage && data.errorMessage}
            </Paragraph>
            {/* {data.fieldPlaceholder != null && (
              <div className={styles.appLinks}>
                <AppLinks assets={assets} browserLink={true} isBlack={false} />
              </div>
            )} */}
          </div>
          <div className={styles.heroImageContainer}>
            <GatsbyImage
              alt={data.image.title}
              className={styles.image}
              image={data.image.gatsbyImageData}
            />
          </div>
        </Container>
      )}
    </div>
  )
}
