import React from 'react'
import Section from '../ui/section'
import Divider from '../ui/divider'
import Typography from '../ui/typography'
import logoWhite from './logo_white.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './footer.module.css'
const isBrowser = typeof window !== 'undefined'

const { Paragraph } = Typography

const year = new Date().getFullYear()

export default (props) => {
  return (
    <Section>
      <div className="px-4 md:px-0 lg:px-20">
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <img alt="Wagetap logo" className="w-40" src={logoWhite} />
            <h2 className={styles.title}>{props.data.title}</h2>
            {/* <div className={styles.appLinks}>
              <AppLinks
                assets={props.assets}
                browserLink={true}
                isBlack={false}
              />
            </div> */}
          </div>
          <div className={styles.linksContainer}>
            <div className={styles.linksGroup}>
              <div className={styles.linkSubGroup}>
                <LinkSet
                  title={props.data.section1Title}
                  links={props.data.section1Paths}
                  hasCustomerConsent={props.hasCustomerConsent}
                />
              </div>
              <div className={styles.linkSubGroup}>
                <LinkSet
                  title={props.data.section2Title}
                  links={props.data.section2Paths}
                  hasCustomerConsent={props.hasCustomerConsent}
                />
              </div>
            </div>
            {/* <div className={styles.linksGroup}>
              <div className={styles.linkSubGroup}>
                <LinkSet
                  title={props.data.section3Title}
                  links={props.data.section3Paths}
                />
              </div>
            </div> */}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-center">
          <Paragraph className="text-sm text-pewterBlue opacity-75 font-light">
            © {year} Wagetap All rights reserved
          </Paragraph>
        </div>
        <div className="flex flex-row justify-center">
          <Paragraph className="text-sm text-pewterBlue opacity-75 font-light">
            {props.data.companyName}
          </Paragraph>
        </div>
      </div>
    </Section>
  )
}

async function onNavigate(linkLabel, hasCustomerConsent) {
  if (isBrowser && hasCustomerConsent) {
    // // Convert spaces to underscore, change to lowercase
    const eventLabel = linkLabel.replaceAll(' ', '_').toLowerCase()
    // // e.g. website_footer_clicked > about_us
    await window.analytics.track('website_footer_clicked', {
      link: eventLabel,
    })
  }
  // Return true to navigate to href of anchor
  return true
}

const LinkSet = (props) => (
  <div>
    <Paragraph className={styles.linkGroupTitle}>{props.title}</Paragraph>
    <ul className="list-none flex flex-col">
      {props.links.map((l, idx) => (
        <li className="md:mt-4 mt-2" key={`footer-link-${l.label}-${idx}`}>
          {l.isHyperlink ? (
            <a className={styles.footerLink} href={l.media?.url ?? l.url} rel="noreferrer" target="_blank">
              <div className={styles.linkDiv}>
                {l.logo && (
                  <GatsbyImage
                    alt={l.logo.title}
                    className="w-4"
                    image={l.logo.gatsbyImageData}
                  />
                )}
                <Paragraph
                  className={`${
                    l.label.includes('@')
                      ? `${styles.linkText} ${styles.linkTextEmail}`
                      : styles.linkText
                  }`}
                >
                  {l.label}
                </Paragraph>
              </div>
            </a>
          ) : (
            <button
              className={styles.footerLink}
              onClick={async (e) => {
                e.preventDefault()
                // send event before navigating away or event is lost
                await onNavigate(l.label, props.hasCustomerConsent)
                window.open(l.url, l.newTab ? '_blank' : '_self')
              }}
            >
              <div className={styles.linkDiv}>
                {l.logo && (
                  <GatsbyImage
                    alt={l.logo.title}
                    className="w-4"
                    image={l.logo.gatsbyImageData}
                  />
                )}
                <Paragraph
                  className={`${
                    l.label.includes('@')
                      ? `${styles.linkText} ${styles.linkTextEmail}`
                      : styles.linkText
                  }`}
                >
                  {l.label}
                </Paragraph>
              </div>
            </button>
          )}
        </li>
      ))}
    </ul>
  </div>
)
