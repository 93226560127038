import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { GreenButton } from '../ui/button'

const isBrowser = typeof window !== 'undefined'

// TODO THIS IS INCORRECT BUT WE'RE NOT USING APPLINKS ATM
const APPSTORE_LINK =
  'https://apps.apple.com/au/app/wagetap-instant-cash-advance/id1513517219'
const PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=com.wagetap&gl=au'
const BROWSER_LINK =
  'https://au.signup.wagetap.com/sign-up?utm_source=wagetap_website&utm_campaign=wagetap_website_app_links'
const BROWSER_DESKTOP_NAV_LINK =
  'https://au.signup.wagetap.com/sign-up?utm_source=wagetap_website&utm_campaign=wagetap_website_desktop_nav'

const DeviceType = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
}

async function trackStoreClick(device) {
  if (isBrowser) {
    const eventName =
      device === DeviceType.IOS
        ? 'ios_button_clicked'
        : 'android_button_clicked'
    await window.analytics.track(eventName, {})
    return true
  }
  return false
}

async function trackBrowserSignupClick() {
  await window.analytics.track('browser_button_clicked', {})
}

export const SignUpNavButton = ({ className }) => (
  <a
    rel="noopener noreferrer"
    href={BROWSER_DESKTOP_NAV_LINK}
    onClick={async () => await trackBrowserSignupClick()}
    target="_blank"
  >
    <GreenButton leftArrow={true} className={className}>
      Sign Up
    </GreenButton>
  </a>
)

export default (props) => {
  return (
    <ul className="list-none flex flex-row items-center mb-6 flex-wrap justify-center md:flex-nowrap">
      {/* Apple App Store */}
      <li className={`mt-4 md:mt-0`} key={`apple-app-link`}>
        <a
          rel="noopener noreferrer"
          href={APPSTORE_LINK}
          onClick={async () => await trackStoreClick(DeviceType.IOS)}
          target="_blank"
        >
          <GatsbyImage
            alt="Download on the Apple App Store"
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            image={
              props.isBlack
                ? props.assets.appStoreBadgeBlack
                : props.isWhite
                ? props.assets.appStoreBadgeWhite
                : props.assets.appStoreBadge
            }
          />
        </a>
      </li>
      {/* Google Play Store */}
      <li
        className={`mt-4 sm:ml-4 md:mt-0  ${props.className || ''}`}
        key={`google-app-link`}
      >
        <a
          rel="noopener noreferrer"
          href={PLAYSTORE_LINK}
          onClick={async () => await trackStoreClick(DeviceType.ANDROID)}
          target="_blank"
        >
          <GatsbyImage
            alt="Get it on Google Play Store"
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            image={
              props.isBlack
                ? props.assets.googlePlayBadgeBlack
                : props.isWhite
                ? props.assets.googlePlayBadgeWhite
                : props.assets.googlePlayBadge
            }
          />
        </a>
      </li>
      {/* Browser */}
      {props.browserLink && (
        <li className="mt-4 md:ml-4 md:mt-0" key={`browser-sign-up-link`}>
          <a
            rel="noopener noreferrer"
            href={BROWSER_LINK}
            onClick={async () => await trackBrowserSignupClick()}
            target="_blank"
          >
            <GatsbyImage
              alt="Sign up with Browser"
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              image={props.assets.browserBadge}
            />
          </a>
        </li>
      )}
    </ul>
  )
}
