import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import './base.css'

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import Seo from "../components/seo"
import Navigation from '../components/ui/navigation/navigation';
import Hero from '../components/ui/hero/hero';
import Footer from "../components/footer/footer"

const isBrowser = typeof window !== 'undefined'

const IndexPage = () => {
  const [hasCustomerConsent, setHasCustomerConsent] = React.useState(false)
  const schemaScript = (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
        {
          "@context": "https://schema.org/",
          "@type": "HowTo",
          "name": "How to get a pay advance",
          "description": "Here are the three simple steps to get a pay advance",
          "step": [{
            "@type": "HowToStep",
            "text": "Download Wagetap by visiting the App Store or Play Store"
          },{
            "@type": "HowToStep",
            "text": "Create your account by providing your name, email address and phone number"
          },{
            "@type": "HowToStep",
            "text": "Pick the amount of money you need to withdraw up to $500"
          }]
        }`,
      }}
    ></script>
  )

  React.useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          services: {
            ga: {
              label: 'Google Analytics',
            },
            segment: {
              label: 'Segment Analytics',
            },
          },
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            // TODO change text
            consentModal: {
              title: 'We use cookies',
              description:
                'These cookies collect information about how you use our website. All of the data is anonymised and cannot be used to identify you.',
              acceptAllBtn: 'Accept',
              acceptNecessaryBtn: 'Reject',
            },
          },
        },
      },
      onConsent: ({ cookie }) => {
        if (cookie.services.analytics.length > 0) {
          setHasCustomerConsent(true);
        } else {
          setHasCustomerConsent(false);
        }
      },
    })
  }, []);

  React.useEffect(() => {
    if (isBrowser && hasCustomerConsent) {
      // Track seeing this page
      window.analytics.track('website_page_viewed', {
        page: 'home',
      });
    }
  }, [hasCustomerConsent]);

  const data = useStaticQuery(graphql`
    query HomeQuery {
      contentfulHomePageContent(
        contentful_id: { eq: "8uOdF7Q5tGhQDWOW4kUPY" }
      ) {
        topNavigationBar {
          logo {
            title
            description
            url
            width
            height
            gatsbyImageData
          }
          title
        }
        pageContent {
          mainContent {
            topBubbleText
            title
            subtitle
            image {
              title
              description
              url
              width
              height
              gatsbyImageData
            }
            imageAlt
            formTitle
            fieldPlaceholder
            successMessage
            errorMessage
            fieldButton
            hideInput
          }
          titleTag
        }
        footerContent {
          title
          section1Title
          section2Title
          # section3Title
          section1Paths {
            url
            newTab
            label
            isHyperlink
            media {
              url
            }
          }
          section2Paths {
            url
            newTab
            label
            isHyperlink
            media {
              url
            }
          }
          companyName
        }
      }
    }
  `)

  return (
    <>
      {data && (
        <>
          <Navigation
            data={data.contentfulHomePageContent.topNavigationBar} hasCustomerConsent={hasCustomerConsent}
          />
          <Hero
            data={data.contentfulHomePageContent.pageContent.mainContent}
          />
          <Footer data={data.contentfulHomePageContent.footerContent} hasCustomerConsent={hasCustomerConsent} />
          {schemaScript}
        </>
      )}
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
